import React from "react"
import Image from "../components/image"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"

import "../styles/swiper/swiper.scss"
import "../styles/swiper/navigation.scss"

SwiperCore.use([Navigation, Autoplay])

function Slider({ slidesList, layout = "", sliderId = "", slidesPerView = 3 }) {
  return (
    <>
      <div className="columns is-centered py-6">
        <div className="column is-12">
          <div className="slider-main">
            <Swiper
              css={SwiperCss}
              spaceBetween={50}
              autoplay
              loop={true}
              loopedSlides={3}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView:
                    slidesPerView > 1 ? slidesPerView - 1 : slidesPerView,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: slidesPerView,
                  spaceBetween: 30,
                },
              }}
              navigation={{
                nextEl: `.swiper-${sliderId}-next`,
                prevEl: `.swiper-${sliderId}-prev`,
              }}
            >
              {slidesList.map((slide, i) => {
                if (slide.link)
                  return (
                    <SwiperSlide key={i}>
                      <a
                        href={slide.link.url}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        {layout === "titleFirst" && (
                          <p className="legend has-text-centered has-text-weight-medium pb-5">
                            {slide.name}
                          </p>
                        )}
                        {slide.image && (
                          <Image sharp={slide.sharp} image={slide.image} />
                        )}
                        {layout !== "titleFirst" && (
                          <p className="legend has-text-centered is-uppercase has-text-weight-semibold">
                            {slide.name}
                          </p>
                        )}
                      </a>
                    </SwiperSlide>
                  )
                if (!slide.link)
                  return (
                    <SwiperSlide key={i}>
                      {layout === "titleFirst" && (
                        <p className="legend has-text-centered has-text-weight-medium pb-5">
                          {slide.name}
                        </p>
                      )}
                      {slide.image && (
                        <Image sharp={slide.sharp} image={slide.image} />
                      )}
                      {layout !== "titleFirst" && (
                        <p className="legend has-text-centered is-uppercase has-text-weight-semibold">
                          {slide.name}
                        </p>
                      )}
                    </SwiperSlide>
                  )
              })}
            </Swiper>

            <div
              role="button"
              name="Previous slide"
              aria-label="Previous slide"
              className={`swiper-button-prev swiper-${sliderId}-prev`}
            ></div>
            <div
              role="button"
              name="Next slide"
              aria-label="Next slide"
              className={`swiper-button-next swiper-${sliderId}-next`}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Slider

const SwiperCss = css`
  .swiper-slide {
    .gatsby-image-wrapper {
      height: 260px;
    }
  }
`
